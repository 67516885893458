<template>
    <div class="setpassword content_getresponse">
        <router-link :to="{name : 'integrations'}" class="close_icon">
            <i class="fal fa-times"></i>
        </router-link>

        <div class="setpassowrd_inner container">

            <div class="top_content_getresponse">
                <img class="get_response_img" src="/img/integrations/sendy.png" alt="">
                <h2>Connect Sendy to Your {{ getSiteDetails.agency_name }} Account</h2>
                <p>Enter Your Sendy Account Details below to connect your Sendy account.</p>
            </div>

            <transition name="slide">
                <form v-if="!show_lists"   class="basic_form clear" action="javascript:;">

                    <div class="input_field col-md-12">
                        <label >Sendy Account Name</label>
                        <input v-model="name" type="text" placeholder="Sendy Account Name">
                    </div>
                    <div class="input_field col-md-12">
                        <label >Sendy List ID</label>
                        <input v-model="listId" type="text" placeholder="The ID of the list where you want to add subscriber">
                    </div>
                    <div class="input_field col-md-12">
                        <label >Sendy List Name</label>
                        <input v-model="listName" type="text" placeholder="The Name of the list where you want to add subscriber">
                    </div>

                    <div class="input_field col-md-12">
                        <label >Sendy API Key</label>
                        <input v-model="api" type="text" placeholder="Sendy API Key">
                    </div>

                    <div class="input_field col-md-12">
                        <label for="pass">Sendy URL</label>
                        <input v-model="url" type="text" placeholder="Sendy URL">
                    </div>
                    <div class="col-md-12 text-center btn_sec">
                        <button :disabled="disable_connect" class="btn---cta btn-blue btn-round"
                                @click.prevent="setSendy()">
                            <span>Connect</span>
                        </button>
                    </div>
                </form>
            </transition>

            <transition name="slide">
                <form v-if="show_lists" class="basic_form clear" action="javascript:;">

                    <ul v-if="lists" class="check_list">
                        <li v-for="list in lists" class="checkbox">
                            <input v-model="selected_lists" type="checkbox" :value="list" :id="list.id">
                            <label :for="list.id">{{list.name}}</label>
                        </li>

                    </ul>

                    <div class="col-md-12 text-center btn_sec">
                        <button class="btn---cta btn-blue btn-round" @click.prevent="saveSendyAccount()">
                            <span>Save</span>

                        </button>
                    </div>
                </form>

            </transition>

        </div>
    </div>
</template>

<script>
import {
  SendyConnectURL
} from '@/config/config.js'
import { mapGetters } from 'vuex'
import http from '@/mixins/http-lib'

export default (
  {
    name: 'Sendy',
    components: {
    },
    data () {
      return {
        api: null,
        url: null,
        listId: null,
        listName: null,
        lists: [],
        show_lists: false,
        selected_lists: [],
        disable_connect: false,
        name: null,
        reconnect: false,
        account_id: ''

      }
    },
    async created () {
      await this.getUserProfile()
      await this.fetchWhitelabelAppSettings()
    },
    computed: {
      ...mapGetters(['getProfile'])
    },
    methods: {

      setSendy () {
        if (this.api == '') {
          this.alertMessage('Please enter your Sendy API Key', 'error')
          return
        }
        if ((this.url.trim()).length == 0) {
          this.alertMessage('Please enter your Sendy URL', 'error')
          return
        }
        if ((this.listId.trim()).length == 0) {
          this.alertMessage('Please enter your Sendy List ID', 'error')
          return
        }
        if ((this.listName.trim()).length == 0) {
          this.alertMessage('Please enter your Sendy List Name', 'error')
          return
        }
        if ((this.name.trim()).length == 0) {
          this.alertMessage('Please enter your Sendy Account Name', 'error')
          return
        }
        this.disable_connect = true
        http.post(SendyConnectURL, {
          api: this.api,
          url: this.url,
          listId: this.listId,
          listName: this.listName,
          name: this.name
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
              this.disable_connect = false
            } else {
              if (response.data.status) {
                this.alertMessage(response.data.message, 'success')
                this.getUserProfile()
                this.$router.push({
                  name: 'integrations'
                })
              } else {
                this.alertMessage(response.data.message, 'error')
              }
              this.disable_connect = false
            }
          },
          response => {
            this.disable_connect = false
            this.alertMessage('Unknown error occurred, support has been notified.', 'error')
          })
      }

    }
  }
)
</script>
